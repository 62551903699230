<!-- 招贤纳士 -->
<template>
  <div class="recruitment wrapper pageBox">
    <div class="banner">
      <div class="banner-text">
        <div class="title">招贤纳士</div>
        <div class="sub">
          <div class="first">中嘉数科</div>
          <div>数字赋能未来生活</div>
        </div>
        <div class="tip">
          医院更智慧  管理更及时  服务更贴心
        </div>
      </div>
      <!-- <div class="bannerText">
        <div class="title">企业介绍</div>
        <div class="btn"><div class="company">中嘉数科</div><div class="colLine" />数字赋能未来生活</div>
      </div> -->
    </div>
    <!-- 内容区域 -->
    <div class="connect">
      <div class="topText">
        <div class="title">招聘岗位</div>
        <div class="sub">JOB  RECRUITMENNT</div>
      </div>
      <div class="conBox">
        <!-- 表单区域 -->
        <el-form :model="queryForm" :inline="true" label-position="center">
          <el-form-item label="类别：">
            <el-select v-model="queryForm.type" size="small">
              <el-option :value="null">全部</el-option>
              <el-option value="产品类">产品类</el-option>
              <el-option value="技术类">技术类</el-option>
              <el-option value="设计类">设计类</el-option>
              <el-option value="市场类">市场类</el-option>
              <el-option value="实施类">实施类</el-option>
              <el-option value="行政类">行政类</el-option>
              <el-option value="财务类">财务类</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属部门：">
            <el-select v-model="queryForm.dept" size="small">
              <el-option :value="null">全部</el-option>
              <el-option value="研发中心-产品部">研发中心-产品部</el-option>
              <el-option value="研发中心-UI设计部">研发中心-UI设计部</el-option>
              <el-option value="研发中心-开发部">研发中心-开发部</el-option>
              <el-option value="研发中心-BIM咨询部">研发中心-BIM咨询部</el-option>
              <el-option value="业务中心-商务部">业务中心-商务部</el-option>
              <el-option value="业务中心-实施运维部">业务中心-实施运维部</el-option>
              <el-option value="人资中心-人力部">人资中心-人力部</el-option>
              <el-option value="财务中心">财务中心</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="城市：">
            <el-select v-model="queryForm.city" size="small">
              <el-option :value="null">全部</el-option>
              <el-option value="杭州">杭州</el-option>
              <el-option value="赣州">赣州</el-option>
              <el-option value="鹰潭">鹰潭</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字：">
            <el-input v-model="queryForm.content" style="width: 250px" size="small" />
          </el-form-item>
          <el-form-item label="">
            <!-- <el-button type="primary" size="small">搜索</el-button> -->
            <div class="search" @click="getList">搜索</div>
          </el-form-item>
        </el-form>
        <!-- 表格区域 -->
        <el-table :data="tableData" stripe :header-cell-style="tableHeaderColor">
          <el-table-column prop="position" label="职位" align="center" />
          <el-table-column prop="type" label="类别" align="center" />
          <el-table-column prop="dept" label="所属部门" align="center" />
          <el-table-column prop="city" label="城市" align="center" />
          <el-table-column label="详情" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="clickInfo(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 翻页 -->
        <div class="pagination-box">
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryForm.currPage"
            :limit.sync="queryForm.pageSize"
            @pagination="getList"
          />
        </div>

        <!-- <div class="topText">
          <div class="title">我们的团队</div>
        </div>

        <div class="show-box">
          <div class="card-box">
            <div class="topTitle tr">行为准则</div>
            <div class="card">
              <img :src="img1" alt="">
              <div class="text-box">
                <div class="title">团队和个体互难分离</div>
                <div class="text-title">追求极致</div>
                <div class="text-content">
                  不断提高要求，延迟满足感，在更大范围里找最优解，思考本质，持续学习
                </div>
                <div class="text-title">自我驱动</div>
                <div class="text-content">
                  不设边界，拥抱变化，对不确定性保持乐观，始终像公司创业第一天那样思考
                </div>
                <div class="text-title">开放坦诚</div>
                <div class="text-content">
                  准确、简洁、直接、有条理和有重点地表达，格局大，内心阳光，信任伙伴
                </div>
              </div>
            </div>
          </div>
          <div class="card-box">
            <div class="topTitle tl">工作气氛</div>
            <div class="card">
              <div class="text-box">
                <div class="title">在平等开放的氛围里工作</div>
                <div class="text-title">务实平等的工作氛围</div>
                <div class="text-content">
                  拒绝官僚主义，敢于授权新人，专注完成业务目标，没有领地意识
                </div>
                <div class="text-title">弹性灵活的工作时间</div>
                <div class="text-content">
                  弹性上下班时间，工作结果导向，效率为先，你的节奏，由你自己来掌控
                </div>
                <div class="text-title">高效开放的办公环境</div>
                <div class="text-content">
                  开放式工位，即时讨论，大小会议空间，让灵感碰撞不受空间约束
                </div>
              </div>
              <img :src="img2" alt="">
            </div>
          </div>
          <div class="card-box">
            <div class="topTitle tr">发展关怀</div>
            <div class="card">
              <img :src="img3" alt="">
              <div class="text-box">
                <div class="title">每一份努力都应该得到回报</div>
                <div class="text-title">股权激励</div>
                <div class="text-content">
                  不仅提倡主人翁意识，更落实实际行动，不仅追求外部共赢，更追求内部共赢
                </div>
                <div class="text-title">项目奖金</div>
                <div class="text-content">
                  按比例划定项目专项奖金，用于奖励标杆项目全体项目组成员的攻坚克难
                </div>
                <div class="text-title">福利关怀</div>
                <div class="text-content">
                  完善的五险一金、加班补贴等，丰富的日常福利帮助大家健康快乐地工作和生活
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="topText">
          <div class="title">员工心声</div>
        </div>

        <div class="staff-box">
          <div v-for="(item, index) in staffSounds" :key="index" class="staff-item">
            <div class="img-box">
              <img :src="item.shortPic" alt="">
            </div>
            <div class="staff-name">{{ item.title }}</div>
            <div class="staff-content">
              {{ item.desction }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getRecruitmentList, getStaffSound } from '@/service/api';
export default {
  name: 'Recruitment',
  components: {},
  data() {
    return {
      // 查询表单数据
      queryForm: {
        type: null,
        dept: null,
        city: null,
        content: null,
        currPage: 1,
        pageSize: 10
      },
      // 总数
      total: 0,
      // 职位表格数据
      tableData: null,
      // 展示图片
      img1: 'https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/img1.png',
      img2: 'https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/img2.png',
      img3: 'https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/img3.png',
      jobInfo: require('@/assets/img/jobInfo.png'),
      // 员工心声列表
      staffSounds: null
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getList();
    this.getStaffSound();
  },
  methods: {
    // 获取列表信息
    getList() {
      const params = this.queryForm;
      getRecruitmentList(params).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.totalCount;
      });
    },
    // 修改table header的背景色
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #f7f7f8;color: #252b3a;';
      }
    },
    clickInfo(row) {
      this.$router.push({
        path: '/about/recruitment/info',
        query: {
          id: row.id
        }
      });
    },
    getStaffSound() {
      getStaffSound().then((res) => {
        this.staffSounds = res.page;
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.recruitment {
  .banner {
    width: 100%;
    height: 720px;
    background: url('https://static.zhongjiadata.com/images/20250227/5eb6157d51bd4077a47b1c52bbe93497.png') no-repeat center center;
    background-size: cover;
    .banner-text {
      padding-top: 240px;
      width: 1384px;
      margin: 0 auto;
      .title {
        font-weight: 600;
        color: #fff;
        line-height: 84px;
        font-size: 60px;
      }
      .sub {
        width: 353px;
        height: 48px;
        background: #ffffff;
        border-radius: 7px;
        color: #418bdb;
        font-size: 24px;
        display: flex;
        align-items: center;
        padding-left: 14px;
        font-weight: 600;
        margin-bottom: 12px;
        .first {
          padding-right: 15px;
          margin-right: 15px;
          height: 34px;
          border-right: 1px solid #438ada;
        }
      }
      .tip {
        color: #ffffff;
        line-height: 27px;
        font-size: 20px;
      }
      .btn {
        width: 229px;
        height: 48px;
        background: #3873ff;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 24px;
        font-weight: 400;
      }
    }
  }
}
.btn {
  width: 251px !important;
  background: rgba(255, 255, 255, 0.15) !important;
  .company {
    color: #2C6EFF;
  }
}
.topText {
  height: auto;
  color: #333;
  margin-bottom: 40px;
  .title {
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
  }
  .sub {
    line-height: 26px;
    font-size: 26px;
  }
}
.connect {
  .conBox {
    margin: 0 auto;
    width: 1280px;
    .el-form {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 32px;
        background: #0279FF;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
        color: #fff;
      }
    }
    /deep/.el-table {
      .el-button {
        color: #2E61FF;
      }
      .el-table__header-wrapper {
        tr {
          th {
            background-color: #e6ecf2 !important;
          }
        }
      }
      .el-table__body-wrapper {
        .el-table__row--striped {
          .el-table__cell {
            background-color: #eceff3;
          }
        }
      }
      .el-table__cell {
        padding: 6px 0;
      }
    }
    .pagination-box {
      padding-top: 50px;
      margin-bottom: 240px;
    }
    .show-box {
      .card-box {
        margin-bottom: 60px;
        &:last-child {
          margin-bottom: 0;
        }
        .topTitle {
          font-size: 22px;
          font-weight: 500;
          color: #252b3a;
          margin-bottom: 19px;
        }
        .card {
          display: flex;
          img {
            width: 680px;
            height: 360px;
          }
          .text-box {
            flex: 1;
            background-color: #f8f9fa;
            padding: 45px 20px 30px 58px;
            height: 360px;
            .title {
              font-size: 22px;
              font-weight: 700;
              color: #252b3a;
              margin-bottom: 28px;
            }
            .text-title {
              font-size: 16px;
              font-weight: 700;
              color: #252b3a;
              margin-bottom: 12px;
              margin-top: 23px;
            }
            .text-content {
              font-size: 14px;
              color: #6a6e77;
              text-indent: 2em;
            }
          }
        }
      }
    }

    .staff-box {
      width: 100%;
      display: flex;
      margin-bottom: 110px;
      .staff-item {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-box {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          border: none;
          overflow: hidden;
        }
        .staff-name {
          font-size: 16px;
          font-weight: 700;
          color: #252b3a;
          margin-top: 32px;
          margin-bottom: 15px;
        }
        .staff-content {
          width: 200px;
          font-size: 14px;
          line-height: 26px;
          color: #6a6e77;
          text-align: center;
        }
      }
    }
  }
}
</style>
